const instructorReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_GROUPS':
            return {
                ...state,
                ...action.payload
            }
        case 'SWITCH_GROUP':
            return {
                ...state,
                ...action.payload
            }
        case 'REFRESH_GROUPS':
            return {
                ...state,
                ...action.payload
            }
        case 'GET_NEW_REQUESTS':
            return {
                ...state,
                ...action.payload
            }
        case 'GET_TOPICS':
            return {
                ...state,
                ...action.payload
            }
        case 'SWITCH_TOPIC':
            return {
                ...state,
                ...action.payload
            }
        case 'FILTER_BY_STUDENT':
            return {
                ...state,
                ...action.payload
            }
        case 'SORT_STUDENTS_BY_WRONG_ANSWERS':
            return {
                ...state,
                ...action.payload
            }
        case 'SWITCH_TO_INVITE':
            return {
                ...state,
                ...action.payload
            }
        case 'VIRTUAL_CLASSROOM':
            return {
                ...state,
                ...action.payload
            }
        case 'EXAM':
            return {
                ...state,
                ...action.payload
            }
        case 'GET_ACTIVE_TEST':
            return {
                ...state,
                ...action.payload
            }
        case 'GET_ACTIVE_EXAM':
            return {
                ...state,
                ...action.payload
            }
        case 'SET_TOURS':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default instructorReducer