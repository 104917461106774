import { combineReducers } from 'redux'
//Списък с reducer-и
import tradeObjectsReducer from './tradeObjects'
import notificationsReducer from './notifications'
import globalModalsReducer from './globalModals'
import instructorReducer from './instructor'

const rootReducer = combineReducers({
    //Списък с reducer-и
    tradeObjectsReducer,
    notificationsReducer,
    globalModalsReducer,
    instructorReducer
})

export default rootReducer