const notificationsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'WS_REFRESH':
            return {
                ...state,
                ...action.payload
            }
        case 'GET_NOTIFICATIONS':
            return {
                ...state,
                ...action.payload
            }
        case 'GET_NOTIFICATIONS_COUNT':
            return {
                ...state,
                ...action.payload
            }
        case 'HANDLE_NOTIFICATION':
            return {
                ...state,
                ...action.payload
            }
        case 'GET_NOTIFICATION_ON_FOCUS':
            return {
                ...state,
                ...action.payload
            }
        case 'NOTIFICATION_MARK_AS_SEEN':
            return {
                ...state,
                ...action.payload
            }
        case 'NOTIFICATION_MARK_AS_READ':
            return {
                ...state,
            }
        case 'END_TEST':
            return {
                ...state,
                ...action.payload
            }
        case 'VIDEOS':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default notificationsReducer