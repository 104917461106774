import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import AuthDataProvider from "./components/../__providers/AuthProvider"
import { BrowserRouter as Router } from "react-router-dom"
import * as serviceWorker from './serviceWorker'

//
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './__reducers/index'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'

const App = lazy(() => import('./App'))

const history = require("history").createLocation
const store = createStore(rootReducer, applyMiddleware(thunk))


ReactDOM.render(
    <Router history={history}>
        <AuthDataProvider>
            <Provider store={store}>
                <Suspense fallback={<>.</>}>
                    <App />
                </Suspense>
            </Provider>
        </AuthDataProvider>
    </Router>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
