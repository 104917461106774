const tradeObjectsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_TRADE_OBJECTS':
            return {
                ...state,
                ...action.payload
            }
        case 'FILTER_TRADE_OBJECTS':
            return {
                ...state,
                ...action.payload
            }
        case 'IS_ONLINE_STORE':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default tradeObjectsReducer